import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceZatcaStatusConstantOnFE } from '../types';

@Pipe({
  name: 'zatcaStatus',
})
export class ZatcaStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case InvoiceZatcaStatusConstantOnFE.Accepted:
      case InvoiceZatcaStatusConstantOnFE.AcceptedWithWarnings:
        return 'paid';
      case InvoiceZatcaStatusConstantOnFE.Pending:
      case InvoiceZatcaStatusConstantOnFE.WaitingOnStatus:
        return 'in_progress';
      case InvoiceZatcaStatusConstantOnFE.Rejected:
      case InvoiceZatcaStatusConstantOnFE.NotSubmitted:
      default:
        return 'payment_due';
    }
  }
}
