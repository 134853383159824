'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ITF14 = exports.ITF = undefined;
var _ITF = require('./ITF');
var _ITF2 = _interopRequireDefault(_ITF);
var _ITF3 = require('./ITF14');
var _ITF4 = _interopRequireDefault(_ITF3);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
exports.ITF = _ITF2.default;
exports.ITF14 = _ITF4.default;