/** @todo discuss where we should store constants */

export const AppNames = {
  Accounting: 'Accounting',
  Zatca: 'ZATCA',
  Expenses: 'Expenses',
  PromotionManagement: 'promotion management',
  Salla: 'Salla',
  NewPos: 'new POS',
  Zid: 'Zid',
  Woocommerce: 'woocommerce',
  Magento: 'magento',
  Bonat: 'bonat',
};

export const bankActualNames = [
  'Al Rajhi Bank',
  'Riyad Bank',
  'Al Jazeera Bank',
  'Saudi National Bank (SNB)',
];

export const banks = [
  {
    name: 'alRajhi',
    actualName: 'Al Rajhi Bank',
    logo: 'alrajhi.svg',
    companyName: 'شركة رواء التقنية لتقنية المعلومات',
    iban: 'SA38 8000 0504 6080 1025 5470',
    accountNumber: '504608010255470',
  },
  {
    name: 'alRiyad',
    actualName: 'AlRiyad Bank',
    logo: 'alriyad.svg',
    companyName: 'شركة رواء التقنية لتقنية المعلومات',
    iban: 'SA74 2000 0002 4116 3936 9940',
    accountNumber: '2411639369940',
  },
  {
    name: 'alJazeera',
    actualName: 'Al Jazeera Bank',
    logo: 'aljazeera.svg',
    companyName: 'شركة رواء التقنية لتقنية المعلومات',
    iban: 'SA46 0001 0395 6950 0007 6010',
    accountNumber: '007695003950001',
  },
];

export const BillingDocumentTypes = {
  Invoice: 'invoice',
  CreditNote: 'credit_note',
};

export enum ItemAttachmentType {
  Mandatory = 'mandatory',
  Recommended = 'recommended',
  OnDemand = 'on_demand',
}

export const ImagesAbsolutePath = '../../../../assets/images';

export const ItemIdToImageSrc = {
  salla: `${ImagesAbsolutePath}/salla.svg`,
  qoyod: `${ImagesAbsolutePath}/qoyod.svg`,
  woocommerce: `${ImagesAbsolutePath}/woocommerce.svg`,
  zid: `${ImagesAbsolutePath}/zid.svg`,
  magento: `${ImagesAbsolutePath}/magento.svg`,
  accounting: `${ImagesAbsolutePath}/accounting.svg`,
  expenses: `${ImagesAbsolutePath}/expenses.svg`,
  promotions: `${ImagesAbsolutePath}/promotions.svg`,
  zatca: `${ImagesAbsolutePath}/zatca.svg`,
  loyalty: '',
  bonat: `${ImagesAbsolutePath}/bonat.svg`,
  sure_pay: `${ImagesAbsolutePath}/sure_pay.svg`,
  payment: '',
};

export enum SubscriptionStatus {
  Active = 'active',
  InTrial = 'in_trial',
  Cancelled = 'cancelled',
  Future = 'future',
  NonRenewing = 'non_renewing',
  Paused = 'paused',
  Transferred = 'transferred',
  NoSubscription = 'no_subscription',
  TrialCancelled = 'trial_cancelled',
}

export enum CouponApplicabilityStatus {
  Applicable = 'applicable',
  NotApplicable = 'not_applicable',
  NotFound = 'not_found',
  IsDuplicate = 'is_duplicate',
  LimitExceeded = 'redemption_limit_exceeded',
}

export enum CouponTypes {
  ItemLevel = 'item_level_coupon',
  InvoiceLevel = 'document_level_coupon',
}

export enum ShippingAddressTypes {
  Delivery = 'delivery',
  PickUp = 'pick-up',
}

export const HardWarePickUpPoints = [
  { name: 'Riyadh Office', value: 'Riyadh Office' },
  { name: 'Rewaa Branch', value: 'Rewaa Branch' },
];

export const citiesList = [
  { name: 'Riyadh' },
  { name: 'Jeddah' },
  { name: 'Makkah' },
  { name: 'Medina' },
  { name: 'Dammam' },
  { name: 'Medina' },
  { name: 'Taif' },
  { name: 'Tabuk' },
  { name: 'Hail' },
  { name: 'Najran' },
  { name: 'Jizan' },
  { name: 'Abha' },
  { name: 'Buraydah' },
  { name: 'Unaizah' },
  { name: 'Arar' },
];

const smallCities = [
  { name: 'ضرما' },
  { name: 'الحريق' },
  { name: 'حوطة بني تميم' },
  { name: 'الأفلاج' },
  { name: 'الدلم' },
  { name: 'الحوطة' },
  { name: 'الدوادمي' },
  { name: 'ساجر' },
  { name: 'الجمش' },
  { name: 'الداهنة' },
  { name: 'البجادية' },
  { name: 'الرويضة' },
  { name: 'البشائر' },
  { name: 'العقيق' },
  { name: 'المخواة' },
  { name: 'بني حسن' },
  { name: 'محايل عسير' },
  { name: 'تبالة' },
  { name: 'العرضيات' },
  { name: 'العرضيتين' },
  { name: 'بيش' },
  { name: 'صامطة' },
  { name: 'العارضة' },
  { name: 'الطوال' },
  { name: 'الحرث' },
  { name: 'فرسان' },
  { name: 'أبو عريش' },
  { name: 'الداير بني مالك' },
  { name: 'العويقلية' },
  { name: 'الشعبة' },
  { name: 'حفر الباطن' },
  { name: 'الرقعي' },
  { name: 'النعيرية' },
  { name: 'الخفجي' },
  { name: 'القرية العليا' },
  { name: 'عين دار' },
  { name: 'بقيق' },
  { name: 'عريعرة' },
  { name: 'السفانية' },
  { name: 'رأس الخير' },
  { name: 'رأس تنورة' },
  { name: 'الجبيل الصناعية' },
  { name: 'الجبيل البلد' },
  { name: 'ثول' },
  { name: 'رابغ' },
  { name: 'مستورة' },
  { name: 'الكامل' },
  { name: 'خليص' },
  { name: 'عسفان' },
  { name: 'بحرة' },
  { name: 'الشعيبة' },
  { name: 'الليث' },
  { name: 'أضم' },
  { name: 'العرضية الشمالية' },
  { name: 'العرضية الجنوبية' },
  { name: 'حلي' },
  { name: 'الخالدية' },
  { name: 'الخرمة' },
  { name: 'الموية' },
  { name: 'تربة' },
  { name: 'رنية' },
  { name: 'ميسان' },
  { name: 'السحن' },
  { name: 'ثقيف' },
  { name: 'حداد بني مالك' },
  { name: 'القريع بني مالك' },
  { name: 'الجعبة' },
  { name: 'خيبر' },
  { name: 'الحناكية' },
  { name: 'العيص' },
  { name: 'بدر' },
  { name: 'وادي الفرع' },
  { name: 'مهد الذهب' },
  { name: 'الحسو' },
  { name: 'النخيل' },
  { name: 'العرجاء' },
  { name: 'السويرقية' },
  { name: 'الحمنة' },
  { name: 'الميقات' },
  { name: 'الصويدرة' },
  { name: 'أم البرك' },
  { name: 'العوشزية' },
  { name: 'الروضة' },
  { name: 'بدائع ريمان' },
  { name: 'الماوية' },
  { name: 'الدائر' },
  { name: 'القوز' },
  { name: 'قنا' },
  { name: 'وادي بن هشبل' },
  { name: 'الحريض' },
  { name: 'الشقيق' },
  { name: 'جزر فرسان' },
  { name: 'جازان' },
  { name: 'الداير' },
  { name: 'الريث' },
  { name: 'الدرب' },
  { name: 'أحد المسارحة' },
  { name: 'ضمد' },
  { name: 'صبيا' },
  { name: 'الموسم' },
  { name: 'الخوبة' },
  { name: 'الحقو' },
  { name: 'العشة' },
  { name: 'النور' },
  { name: 'الجبيلية' },
  { name: 'الجدة' },
  { name: 'حقل' },
  { name: 'حالة عمار' },
  { name: 'تيماء' },
  { name: 'ضباء' },
  { name: 'الوجه' },
  { name: 'أملج' },
  { name: 'الحيرة' },
  { name: 'الفرشة' },
  { name: 'المضة' },
  { name: 'تندحة' },
  { name: 'خيبر الجنوب' },
  { name: 'القحمة' },
  { name: 'العرين' },
  { name: 'بلقرن' },
  { name: 'خثعم' },
  { name: 'شواص' },
  { name: 'عفراء' },
  { name: 'تنومة' },
  { name: 'سعيدة الصوالحة' },
  { name: 'الحجلة' },
  { name: 'القوباء' },
  { name: 'البرك' },
  { name: 'خميس مشيط' },
  { name: 'النماص' },
  { name: 'الحرجة' },
  { name: 'ظهران الجنوب' },
  { name: 'عبس' },
  { name: 'قلوة' },
  { name: 'بلجرشي' },
  { name: 'الحجرة' },
  { name: 'غامد الزناد' },
  { name: 'القرى' },
  { name: 'المندق' },
  { name: 'ثريبان' },
  { name: 'نمرة' },
  { name: 'المظيلف' },
  { name: 'دوقة' },
  { name: 'سبت الجارة' },
  { name: 'رجال ألمع' },
  { name: 'بارق' },
  { name: 'بيشة' },
  { name: 'تثليث' },
  { name: 'الحازمي' },
  { name: 'صمخ' },
  { name: 'سبت العلايا' },
  { name: 'بللسمر' },
  { name: 'بللحمر' },
  { name: 'سراة عبيدة' },
  { name: 'أحد رفيدة' },
  { name: 'خميس البحر' },
  { name: 'الحريضة' },
  { name: 'فيفاء' },
  { name: 'العيدابي' },
  { name: 'هروب' },
  { name: 'السهي' },
  { name: 'تبوك' },
  { name: 'الحارة' },
  { name: 'الخريبة' },
  { name: 'الجهراء' },
  { name: 'المويلح' },
  { name: 'البدع' },
  { name: 'جبة' },
  { name: 'حائل' },
  { name: 'الخطة' },
  { name: 'شري' },
  { name: 'الحائط' },
  { name: 'سميراء' },
  { name: 'الشملي' },
  { name: 'السليمي' },
  { name: 'الغزالة' },
  { name: 'موقق' },
  { name: 'روض بن هادي' },
  { name: 'الحليفة' },
  { name: 'البير' },
  { name: 'الأرطاوية' },
  { name: 'ثادق' },
  { name: 'حريملاء' },
  { name: 'رماح' },
  { name: 'مرات' },
  { name: 'عفيف' },
  { name: 'نفي' },
  { name: 'عروى' },
  { name: 'البدائع' },
  { name: 'النبهانية' },
  { name: 'البكيرية' },
  { name: 'الأسياح' },
  { name: 'عيون الجواء' },
  { name: 'عقلة الصقور' },
  { name: 'الفويلق' },
  { name: 'الربيعية' },
  { name: 'عريفجان' },
  { name: 'فيد' },
  { name: 'رمان' },
];

const mediumCities = [
  { name: 'الباحة' },
  { name: 'المجاردة' },
  { name: 'القنفذة' },
  { name: 'المذنب' },
  { name: 'السليل' },
  { name: 'وادي الدواسر' },
  { name: 'عرعر' },
  { name: 'رفحاء' },
  { name: 'طريف' },
  { name: 'القريات' },
  { name: 'سكاكا' },
  { name: 'دومة الجندل' },
  { name: 'القويعية' },
  { name: 'شقراء' },
  { name: 'الزلفي' },
  { name: 'المجمعة' },
  { name: 'عنيزة' },
  { name: 'الرس' },
  { name: 'رياض الخبراء' },
  { name: 'البدائع' },
  { name: 'البكيرية' },
  { name: 'النبهانية' },
];

const majorCities = [
  { name: 'الرياض' },
  { name: 'جدة' },
  { name: 'مكة المكرمة' },
  { name: 'المدينة المنورة' },
  { name: 'الدمام' },
  { name: 'الخبر' },
  { name: 'الطائف' },
  { name: 'تبوك' },
  { name: 'بريدة' },
  { name: 'أبها' },
  { name: 'نجران' },
  { name: 'حائل' },
  { name: 'جيزان' },
  { name: 'القطيف' },
  { name: 'ينبع' },
  { name: 'العلا' },
  { name: 'حفر الباطن' },
  { name: 'الجبيل' },
];

export const cityListsByCountry = {
  ليبيا: [
    { name: 'طرابلس' },
    { name: 'بنغازي' },
    { name: 'مصراتة' },
    { name: 'سبها' },
    { name: 'البيضاء' },
    { name: 'الزاوية' },
    { name: 'سرت' },
    { name: 'درنة' },
    { name: 'طبرق' },
    { name: 'بني وليد' },
    { name: 'زليتن' },
    { name: 'غريان' },
    { name: 'الخمس' },
    { name: 'ترهونة' },
    { name: 'مرزق' },
    { name: 'غدامس' },
    { name: 'الكفرة' },
    { name: 'المرج' },
    { name: 'رأس لانوف' },
    { name: 'البريقة' },
    { name: 'العقيلة' },
    { name: 'أوجلة' },
    { name: 'جالو' },
    { name: 'تينيري' },
    { name: 'أخرى' },
  ],
  السودان: [
    { name: 'الخرطوم' },
    { name: 'أم درمان' },
    { name: 'بورتسودان' },
    { name: 'كسلا' },
    { name: 'الأبيض' },
    { name: 'عطبرة' },
    { name: 'سنار' },
    { name: 'مدني' },
    { name: 'القضارف' },
    { name: 'الفاشر' },
    { name: 'نيالا' },
    { name: 'كادوقلي' },
    { name: 'الجنينة' },
    { name: 'كوستي' },
    { name: 'ربك' },
    { name: 'رفاعة' },
    { name: 'الدمازين' },
    { name: 'شندي' },
    { name: 'سواكن' },
    { name: 'طوكر' },
    { name: 'ود مدني' },
    { name: 'دنقلا' },
    { name: 'كريمة' },
    { name: 'المتمة' },
    { name: 'الدامر' },
    { name: 'النهود' },
    { name: 'أم روابة' },
    { name: 'بارا' },
    { name: 'زالنجي' },
    { name: 'الدلنج' },
    { name: 'أخرى' },
  ],
  المغرب: [
    { name: 'الرباط' },
    { name: 'الدار البيضاء' },
    { name: 'مراكش' },
    { name: 'فاس' },
    { name: 'طنجة' },
    { name: 'مكناس' },
    { name: 'أغادير' },
    { name: 'تطوان' },
    { name: 'وجدة' },
    { name: 'بني ملال' },
    { name: 'القنيطرة' },
    { name: 'آسفي' },
    { name: 'الجديدة' },
    { name: 'سلا' },
    { name: 'خريبكة' },
    { name: 'العيون' },
    { name: 'الصويرة' },
    { name: 'العرائش' },
    { name: 'تارودانت' },
    { name: 'قلعة السراغنة' },
    { name: 'تازة' },
    { name: 'الناظور' },
    { name: 'المحمدية' },
    { name: 'تمارة' },
    { name: 'ورزازات' },
    { name: 'الداخلة' },
    { name: 'بني أنصار' },
    { name: 'أولاد تايمة' },
    { name: 'تاوريرت' },
    { name: 'الخميسات' },
    { name: 'الراشيدية' },
    { name: 'بوعرفة' },
    { name: 'أزرو' },
    { name: 'دمنات' },
    { name: 'الحاجب' },
    { name: 'زايو' },
    { name: 'شفشاون' },
    { name: 'مريرت' },
    { name: 'مولاي إدريس زرهون' },
    { name: 'تارجيست' },
    { name: 'أخرى' },
  ],
  الجزائر: [
    { name: 'الجزائر' },
    { name: 'وهران' },
    { name: 'قسنطينة' },
    { name: 'عنابة' },
    { name: 'تلمسان' },
    { name: 'سطيف' },
    { name: 'البليدة' },
    { name: 'باتنة' },
    { name: 'بجاية' },
    { name: 'ورقلة' },
    { name: 'غرداية' },
    { name: 'تيارت' },
    { name: 'تيزي وزو' },
    { name: 'بشار' },
    { name: 'سكيكدة' },
    { name: 'جيجل' },
    { name: 'قالمة' },
    { name: 'بسكرة' },
    { name: 'خنشلة' },
    { name: 'المدية' },
    { name: 'عين الدفلى' },
    { name: 'مستغانم' },
    { name: 'برج بوعريريج' },
    { name: 'سوق أهراس' },
    { name: 'الأغواط' },
    { name: 'بومرداس' },
    { name: 'تيسمسيلت' },
    { name: 'تبسة' },
    { name: 'سيدي بلعباس' },
    { name: 'المسيلة' },
    { name: 'الجلفة' },
    { name: 'تمنراست' },
    { name: 'إليزي' },
    { name: 'أخرى' },
  ],
  لبنان: [
    { name: 'بيروت' },
    { name: 'طرابلس' },
    { name: 'صيدا' },
    { name: 'بعلبك' },
    { name: 'زحلة' },
    { name: 'جبيل' },
    { name: 'صور' },
    { name: 'النبطية' },
    { name: 'جونية' },
    { name: 'شتورة' },
    { name: 'البترون' },
    { name: 'عاليه' },
    { name: 'حمانا' },
    { name: 'راشيا' },
    { name: 'الميناء' },
    { name: 'عكار' },
    { name: 'بشرّي' },
    { name: 'بكفيا' },
    { name: 'غزير' },
    { name: 'الشوف' },
    { name: 'أخرى' },
  ],
  البحرين: [
    { name: 'المنامة' },
    { name: 'المحرق' },
    { name: 'الرفاع' },
    { name: 'سترة' },
    { name: 'البديع' },
    { name: 'مدينة عيسى' },
    { name: 'مدينة حمد' },
    { name: 'عالي' },
    { name: 'الدير' },
    { name: 'السنابس' },
    { name: 'قلالي' },
    { name: 'الرفاع الشرقي' },
    { name: 'البسيتين' },
    { name: 'الجفير' },
    { name: 'الزنج' },
    { name: 'القضيبية' },
    { name: 'العدلية' },
    { name: 'السهلة' },
    { name: 'جدحفص' },
    { name: 'سار' },
    { name: 'باربار' },
    { name: 'توبلي' },
    { name: 'أخرى' },
  ],
  عمان: [
    { name: 'مسقط' },
    { name: 'صلالة' },
    { name: 'نزوى' },
    { name: 'صحار' },
    { name: 'صور' },
    { name: 'البريمي' },
    { name: 'الرستاق' },
    { name: 'عبري' },
    { name: 'بهلاء' },
    { name: 'خصب' },
    { name: 'بدبد' },
    { name: 'السيب' },
    { name: 'الخابورة' },
    { name: 'شناص' },
    { name: 'قريات' },
    { name: 'صحم' },
    { name: 'بركاء' },
    { name: 'المضيبي' },
    { name: 'الحمراء' },
    { name: 'سمائل' },
    { name: 'إبراء' },
    { name: 'المزيونة' },
    { name: 'دبا' },
    { name: 'مدحاء' },
    { name: 'أخرى' },
  ],
  اليمن: [
    { name: 'صنعاء' },
    { name: 'عدن' },
    { name: 'تعز' },
    { name: 'حضرموت' },
    { name: 'إب' },
    { name: 'الحديدة' },
    { name: 'المكلا' },
    { name: 'سيئون' },
    { name: 'ذمار' },
    { name: 'صعدة' },
    { name: 'عمران' },
    { name: 'لحج' },
    { name: 'المحويت' },
    { name: 'البيضاء' },
    { name: 'ريمة' },
    { name: 'الضالع' },
    { name: 'شبوة' },
    { name: 'أبين' },
    { name: 'حجة' },
    { name: 'أخرى' },
  ],
  الكويت: [
    { name: 'مدينة الكويت' },
    { name: 'الجهراء' },
    { name: 'السالمية' },
    { name: 'الفحيحيل' },
    { name: 'الأحمدي' },
    { name: 'حولي' },
    { name: 'مبارك الكبير' },
    { name: 'الفروانية' },
    { name: 'صباح السالم' },
    { name: 'أبو حليفة' },
    { name: 'الصليبيخات' },
    { name: 'الرميثية' },
    { name: 'بيان' },
    { name: 'مشرف' },
    { name: 'السالمية الجديدة' },
    { name: 'الزهراء' },
    { name: 'ضاحية عبد الله السالم' },
    { name: 'العدان' },
    { name: 'أخرى' },
  ],
  قطر: [
    { name: 'الدوحة' },
    { name: 'الريان' },
    { name: 'الوكرة' },
    { name: 'الخور' },
    { name: 'الشحانية' },
    { name: 'أم صلال' },
    { name: 'الزبارة' },
    { name: 'الغويرية' },
    { name: 'الدحيل' },
    { name: 'الوجبة' },
    { name: 'معيذر' },
    { name: 'الغرافة' },
    { name: 'المطار القديم' },
    { name: 'السد' },
    { name: 'بن عمران' },
    { name: 'جريان البطنة' },
    { name: 'العسيري' },
    { name: 'الجميلية' },
    { name: 'لعبيب' },
    { name: 'أخرى' },
  ],
  الأردن: [
    { name: 'عمان' },
    { name: 'الزرقاء' },
    { name: 'إربد' },
    { name: 'العقبة' },
    { name: 'الكرك' },
    { name: 'المفرق' },
    { name: 'مادبا' },
    { name: 'السلط' },
    { name: 'جرش' },
    { name: 'عجلون' },
    { name: 'الطفيلة' },
    { name: 'الرمثا' },
    { name: 'معان' },
    { name: 'الفحيص' },
    { name: 'الأزرق' },
    { name: 'سحاب' },
    { name: 'الحصن' },
    { name: 'أخرى' },
  ],
  'الإمارات العربية المتحدة': [
    { name: 'أبوظبي' },
    { name: 'دبي' },
    { name: 'الشارقة' },
    { name: 'العين' },
    { name: 'رأس الخيمة' },
    { name: 'عجمان' },
    { name: 'أم القيوين' },
    { name: 'الفجيرة' },
    { name: 'خورفكان' },
    { name: 'دبا الفجيرة' },
    { name: 'الذيد' },
    { name: 'مدينة زايد' },
    { name: 'الرويس' },
    { name: 'المرفأ' },
    { name: 'دلما' },
    { name: 'حتا' },
    { name: 'ليوا' },
    { name: 'أخرى' },
  ],
  'المملكة العربية السعودية': [
    ...majorCities,
    ...smallCities,
    ...mediumCities,
    { name: 'القيصومة' },
    { name: 'الأحساء' },
    { name: 'الخرج' },
    { name: 'وادي الدواسر' },
    { name: 'الهفوف' },
    { name: 'حوطة سدير' },
    { name: 'أخرى' },
  ],
  مصر: [
    { name: 'القاهرة' },
    { name: 'الإسكندرية' },
    { name: 'الجيزة' },
    { name: 'أسوان' },
    { name: 'الأقصر' },
    { name: 'أسيوط' },
    { name: 'المنصورة' },
    { name: 'السويس' },
    { name: 'بورسعيد' },
    { name: 'المنيا' },
    { name: 'بني سويف' },
    { name: 'قنا' },
    { name: 'دمياط' },
    { name: 'سوهاج' },
    { name: 'الفيوم' },
    { name: 'الزقازيق' },
    { name: 'مرسى مطروح' },
    { name: 'الإسماعيلية' },
    { name: 'العريش' },
    { name: 'شرم الشيخ' },
    { name: 'الغردقة' },
    { name: 'طنطا' },
    { name: 'شبين الكوم' },
    { name: 'دمنهور' },
    { name: 'كفر الشيخ' },
    { name: 'بنها' },
    { name: 'ملوي' },
    { name: 'السادات' },
    { name: 'إدفو' },
    { name: 'ديروط' },
    { name: 'المنزلة' },
    { name: 'نصر النوبة' },
    { name: 'أبو سمبل' },
    { name: 'أخرى' },
  ],
  العراق: [
    { name: 'بغداد' },
    { name: 'البصرة' },
    { name: 'الموصل' },
    { name: 'أربيل' },
    { name: 'كربلاء' },
    { name: 'النجف' },
    { name: 'السليمانية' },
    { name: 'دهوك' },
    { name: 'الناصرية' },
    { name: 'العمارة' },
    { name: 'الرمادي' },
    { name: 'الديوانية' },
    { name: 'الكوت' },
    { name: 'السماوة' },
    { name: 'الحلة' },
    { name: 'الفلوجة' },
    { name: 'سامراء' },
    { name: 'تكريت' },
    { name: 'كركوك' },
    { name: 'الحويجة' },
    { name: 'المقدادية' },
    { name: 'البعاج' },
    { name: 'بيجي' },
    { name: 'تلعفر' },
    { name: 'القائم' },
    { name: 'أخرى' },
  ],
};

export const countriesList = [
  { name: 'المملكة العربية السعودية' },
  { name: 'مصر' },
  { name: 'العراق' },
  { name: 'الإمارات العربية المتحدة' },
  { name: 'الأردن' },
  { name: 'قطر' },
  { name: 'الكويت' },
  { name: 'اليمن' },
  { name: 'عمان' },
  { name: 'البحرين' },
  { name: 'لبنان' },
  { name: 'الجزائر' },
  { name: 'المغرب' },
  { name: 'السودان' },
  { name: 'ليبيا' },
];

export const countryCode = {
  'المملكة العربية السعودية': 'SA',
  مصر: 'EG',
  العراق: 'IQ',
  'الإمارات العربية المتحدة': 'AE',
  الأردن: 'JO',
  قطر: 'QA',
  الكويت: 'KW',
  اليمن: 'YE',
  عمان: 'OM',
  البحرين: 'BH',
  لبنان: 'LB',
  الجزائر: 'DZ',
  المغرب: 'MA',
  السودان: 'SD',
  ليبيا: 'LY',
};

export const MapItemIdToNames = {
  pos: 'new pos',
  salla: 'salla',
  qoyod: 'qoyod',
  woocommerce: 'woocommerce',
  zid: 'zid',
  magento: 'magento',
  accounting: 'accounting',
  expenses: 'expenses',
  promotions: 'promotions',
  zatca: 'zatca',
  loyalty: 'loyalty',
  bonat: 'bonat',
  sure_pay: 'sure pay',
  payment: 'payments',
};

export const temp = [
  {
    featureId: 'transfer-stock',
    itemId: null,
  },
  {
    featureId: 'zid',
    itemId: 'zid',
  },
  {
    featureId: 'supplier-management',
    itemId: null,
  },
  {
    featureId: 'stock-control',
    itemId: null,
  },
  {
    featureId: 'payment',
    itemId: 'payment',
  },
  {
    featureId: 'qoyod',
    itemId: null,
  },
  {
    featureId: 'accounting',
    itemId: 'accounting',
  },
  {
    featureId: 'dashboard',
    itemId: null,
  },
  {
    featureId: 'bonat',
    itemId: 'bonat',
  },
  {
    featureId: 'stock-count',
    itemId: null,
  },
  {
    featureId: 'sure_pay',
    itemId: 'sure_pay',
  },
  {
    featureId: 'expenses',
    itemId: 'expenses',
  },
  {
    featureId: 'pos',
    itemId: null,
  },
  {
    featureId: 'salla',
    itemId: 'salla',
  },
  {
    featureId: 'zatca',
    itemId: 'zatca',
  },
  {
    featureId: 'sku',
    itemId: null,
  },
  {
    featureId: 'loyalty',
    itemId: 'loyalty',
  },
  {
    featureId: 'magento',
    itemId: 'magento',
  },
  {
    featureId: 'locations',
    itemId: null,
  },
  {
    featureId: 'promotions',
    itemId: 'promotions',
  },
  {
    featureId: 'custom-fields',
    itemId: null,
  },
  {
    featureId: 'woocommerce',
    itemId: 'woocommerce',
  },
  {
    featureId: 'customer-management',
    itemId: null,
  },
  {
    featureId: 'inventory',
    itemId: null,
  },
  {
    featureId: 'purchase-order',
    itemId: null,
  },
  {
    featureId: 'location',
    itemId: 'location',
  },
  {
    featureId: 'reports',
    itemId: null,
  },
];

export const additionalIdentifierOptions = [
  'Commercial Registration',
  // 'Freelance License',
  // 'MOMRA License',
  // 'MLSD License',
  // 'SAGIA License',
  // 'Other ID',
];

export const businessCategorieOptions = [
  'أخرى',
  'أدوات بناء أو سباكة او نجارة او حدادة او زجاج',
  'الأثاث او المطابخ',
  'الأجهزة المنزلية',
  'الأندية او الملاعب الرياضية',
  'الإستشارات او الأنشطة القانونية',
  'الاتصالات والتقنية (تشمل الجوالات والكمبيوترات)',
  'البقالات او السوبرماركت او مواد غذائية',
  'الديكور او التصميم',
  'الشيشة والمعسل والسيجار',
  'الصالونات والحلاقة والمساج',
  'العطور او منتجات التجميل',
  'العمالة المنزلية',
  'الغاز المنزلي اوالفحم',
  'الكافيهات ومحلات العصائر',
  'الماء والمرطبات',
  'المخازن او النقل او المناديب',
  'الملابس والأحذية وعبايات',
  'النسيج او الخياطة (تشمل السجاد والستائر)',
  'النشر او الدعاية والإعلان',
  'بيع قطع غيار السيارات',
  'حيوانات اليفة او بيطري',
  'خدمات الإقامة المؤقتة (شقق او قاعات زواج او شاليهات او ادارة فعاليات او صوتيات)',
  'خضار او فواكة او تمور',
  'ذهب ومجوهرات واكسسوارات',
  'صيدلية وبضائع طبية',
  'عقارات',
  'كماليات',
  'مجمعات طبية وعيادات',
  'محطة وقود',
  'محل نظارات',
  'محمصة اوعطارة او مكسرات',
  'مخابز او حلويات',
  'مصانع -تصنيع',
  'مطاعم وبوفيات',
  'معاهد تدريب',
  'معرض سيارات',
  'مغسلة ملابس',
  'مقاولات',
  'مكتبة او مطبعة او استديو تصوير',
  'ملحمة',
  'هدايا او ورد او حرف او تحف او مشاتل',
  'ورش او مغاسل او زينة السيارات',
];

export const previousUsedSystemsOptions = [
  '-None-',
  'Qoyod-قيود',
  'Daftra-دفتره',
  'Fodex-فوديكس',
  'Odoo-اودو',
  'Plugin-بلج ان',
  'Vom-فوم',
  'Mest-مسيت',
  'Bnody-بنودي',
  'Visual Soft-فيجوال سوفت',
  'BetterTech-بيتر تيك',
  'Tally-تالي',
  'Aliphia-الفيا',
  'Dafater-دفاتر',
  'Max Smart Code-ماكس سمارت كود',
  'Wafeq-وافق',
  'Smacc-سماك',
  'Quick Books-كويك يوكس',
  'iSales-اي سيلز',
  'zero-زيرو',
  'ABS',
  'Fatoora-فاتوره',
  'Sohoby-صوحوبي',
  'AccFlex-فيليكس',
  'Webtel-ويب تل',
  'ClearTax-كلير تاس',
  'exerterp-اكسرتيب',
  'accely-اكسلي',
  'olivobusiness-اوليفيو بيسنس',
  'fawater-فواتير',
  'linkitsys-لينك ات سيس',
  'Medad ERP-مداد',
  'dexef-ديكسيف',
  'sahl-سهل',
  'Cashin-كاشن',
  'neoleap-نيوليب',
  'marn-مرن',
  'eyeen-عين',
  'wosul-وصول',
  'Lazywait-ليزي ويت',
  'oxo-اوكسو',
  'Phenix-فينيكس',
  'Microtec-ميكرو تيك',
  'AswaqSoft-اشواق سوفت',
  'HalaCashier-هلا',
  'Al AmeenSoft-الامين',
  'Alshamel-الشامل',
  'prixcel-بريكسيل',
  'Other-أخري',
];

export const RewaaPayIntegrationOption = ['Yes', 'No'];

export const daysToUploadProductOptions = [
  'More than 3 days',
  'Less than 3 days',
];

export const reasonForUploadingProducts = [
  'العميل لم يفتتح نشاطه بعد',
  'لا يزال العميل لديه اشتراك ساري في نظامة القديم',
  'العميل بانتظار اصدار السجل التجاري الخاص بالنشاط',
  'العميل ينتظر الوقت المناسب لتدريب موظفيه',
];

export const webstores = [
  'Majento - ماجنتو',
  'Other',
  'Salla - سله',
  'Woo-Commerce - وو كوميرس',
  'Zid - زد',
];

export const additionalIdentifiersInArabic = {
  'Commercial Registration': 'السجل التجاري ',
  'Freelance License': 'شهادة العمل الحر',
  'MOMRA licenses': ' ترخيص وزارة الشؤون البلدية والقروية',
  'MLSD licenses': 'ترخيص وزارة الموارد البشرية والتنمية االجتماعية',
  'SAGIA licenses': 'ترخيص الهيئة العامة للالستثمار',
  'Other ID': 'معرف آخر',
};
