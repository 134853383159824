<p-dialog
  [showHeader]="false"
  [(visible)]="dialogVisible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '100%', maxWidth: '624px' }"
>
  <div class="flex flex-column gap-6 pb-4">
    <div class="flex flex-column gap-6 pt-3">
      <div class="flex flex-column align-items-center gap-4">
        <div class="flex align-items-center gap-4 pl-6 pr-6">
          <!-- Added flex container here -->
          @if (planWarning) {
            <rwa-info
              [icon]="'fa-regular fa-triangle-exclamation'"
              [type]="'warning'"
              [translationKey]="''"
              [text]="
                '<b>Note:</b> Issuing a credit note for an active subscription will lead to cancel it.'
              "
            ></rwa-info>
          }
          <img
            src="../../../../../assets/icons/success-thumb.svg"
            height="88"
            width="88"
          />
        </div>
        <label class="font-medium text-2xl text-neutral-90">{{
          headingMsg
        }}</label>
        <label
          class="font-medium"
          style="color: #5c7070"
          >{{ msg }}</label
        >
      </div>
    </div>
    <div class="flex flex-column align-items-center gap-4">
      <div class="flex justify-content-center gap-3">
        <p-button
          label="Go to customer account"
          [outlined]="true"
          styleClass="border-neutral-20"
          (click)="close('details')"
        ></p-button>
        <p-button
          label="Print Credit Note"
          [outlined]="true"
          (click)="close('print')"
        ></p-button>
      </div>
    </div>
  </div>
</p-dialog>
