'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();
var _encoder = require('./encoder');
var _encoder2 = _interopRequireDefault(_encoder);
var _Barcode2 = require('../Barcode.js');
var _Barcode3 = _interopRequireDefault(_Barcode2);
var _UPC = require('./UPC.js');
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }
  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}
function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
} // Encoding documentation:
// https://en.wikipedia.org/wiki/Universal_Product_Code#Encoding
//
// UPC-E documentation:
// https://en.wikipedia.org/wiki/Universal_Product_Code#UPC-E

var EXPANSIONS = ["XX00000XXX", "XX10000XXX", "XX20000XXX", "XXX00000XX", "XXXX00000X", "XXXXX00005", "XXXXX00006", "XXXXX00007", "XXXXX00008", "XXXXX00009"];
var PARITIES = [["EEEOOO", "OOOEEE"], ["EEOEOO", "OOEOEE"], ["EEOOEO", "OOEEOE"], ["EEOOOE", "OOEEEO"], ["EOEEOO", "OEOOEE"], ["EOOEEO", "OEEOOE"], ["EOOOEE", "OEEEOO"], ["EOEOEO", "OEOEOE"], ["EOEOOE", "OEOEEO"], ["EOOEOE", "OEEOEO"]];
var UPCE = function (_Barcode) {
  _inherits(UPCE, _Barcode);
  function UPCE(data, options) {
    _classCallCheck(this, UPCE);
    var _this = _possibleConstructorReturn(this, (UPCE.__proto__ || Object.getPrototypeOf(UPCE)).call(this, data, options));
    // Code may be 6 or 8 digits;
    // A 7 digit code is ambiguous as to whether the extra digit
    // is a UPC-A check or number system digit.

    _this.isValid = false;
    if (data.search(/^[0-9]{6}$/) !== -1) {
      _this.middleDigits = data;
      _this.upcA = expandToUPCA(data, "0");
      _this.text = options.text || '' + _this.upcA[0] + data + _this.upcA[_this.upcA.length - 1];
      _this.isValid = true;
    } else if (data.search(/^[01][0-9]{7}$/) !== -1) {
      _this.middleDigits = data.substring(1, data.length - 1);
      _this.upcA = expandToUPCA(_this.middleDigits, data[0]);
      if (_this.upcA[_this.upcA.length - 1] === data[data.length - 1]) {
        _this.isValid = true;
      } else {
        // checksum mismatch
        return _possibleConstructorReturn(_this);
      }
    } else {
      return _possibleConstructorReturn(_this);
    }
    _this.displayValue = options.displayValue;

    // Make sure the font is not bigger than the space between the guard bars
    if (options.fontSize > options.width * 10) {
      _this.fontSize = options.width * 10;
    } else {
      _this.fontSize = options.fontSize;
    }

    // Make the guard bars go down half the way of the text
    _this.guardHeight = options.height + _this.fontSize / 2 + options.textMargin;
    return _this;
  }
  _createClass(UPCE, [{
    key: 'valid',
    value: function valid() {
      return this.isValid;
    }
  }, {
    key: 'encode',
    value: function encode() {
      if (this.options.flat) {
        return this.flatEncoding();
      } else {
        return this.guardedEncoding();
      }
    }
  }, {
    key: 'flatEncoding',
    value: function flatEncoding() {
      var result = "";
      result += "101";
      result += this.encodeMiddleDigits();
      result += "010101";
      return {
        data: result,
        text: this.text
      };
    }
  }, {
    key: 'guardedEncoding',
    value: function guardedEncoding() {
      var result = [];

      // Add the UPC-A number system digit beneath the quiet zone
      if (this.displayValue) {
        result.push({
          data: "00000000",
          text: this.text[0],
          options: {
            textAlign: "left",
            fontSize: this.fontSize
          }
        });
      }

      // Add the guard bars
      result.push({
        data: "101",
        options: {
          height: this.guardHeight
        }
      });

      // Add the 6 UPC-E digits
      result.push({
        data: this.encodeMiddleDigits(),
        text: this.text.substring(1, 7),
        options: {
          fontSize: this.fontSize
        }
      });

      // Add the end bits
      result.push({
        data: "010101",
        options: {
          height: this.guardHeight
        }
      });

      // Add the UPC-A check digit beneath the quiet zone
      if (this.displayValue) {
        result.push({
          data: "00000000",
          text: this.text[7],
          options: {
            textAlign: "right",
            fontSize: this.fontSize
          }
        });
      }
      return result;
    }
  }, {
    key: 'encodeMiddleDigits',
    value: function encodeMiddleDigits() {
      var numberSystem = this.upcA[0];
      var checkDigit = this.upcA[this.upcA.length - 1];
      var parity = PARITIES[parseInt(checkDigit)][parseInt(numberSystem)];
      return (0, _encoder2.default)(this.middleDigits, parity);
    }
  }]);
  return UPCE;
}(_Barcode3.default);
function expandToUPCA(middleDigits, numberSystem) {
  var lastUpcE = parseInt(middleDigits[middleDigits.length - 1]);
  var expansion = EXPANSIONS[lastUpcE];
  var result = "";
  var digitIndex = 0;
  for (var i = 0; i < expansion.length; i++) {
    var c = expansion[i];
    if (c === 'X') {
      result += middleDigits[digitIndex++];
    } else {
      result += c;
    }
  }
  result = '' + numberSystem + result;
  return '' + result + (0, _UPC.checksum)(result);
}
exports.default = UPCE;