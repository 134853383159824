"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = fixOptions;
function fixOptions(options) {
  // Fix the margins
  options.marginTop = options.marginTop || options.margin;
  options.marginBottom = options.marginBottom || options.margin;
  options.marginRight = options.marginRight || options.margin;
  options.marginLeft = options.marginLeft || options.margin;
  return options;
}