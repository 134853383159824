"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
var Barcode = function Barcode(data, options) {
  _classCallCheck(this, Barcode);
  this.data = data;
  this.text = options.text || data;
  this.options = options;
};
exports.default = Barcode;