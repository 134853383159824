<div style="width: max-content">
  <form
    [formGroup]="dateFilterForm"
    class="bg-white flex flex-column gap-2"
  >
    <p-listbox
      [options]="options"
      formControlName="selectedTimePeriod"
      optionLabel="key"
      (onChange)="onChangeTimePeriod($event.value.key)"
    ></p-listbox>

    <span class="p-float-label w-full mt-4">
      <p-calendar
        [showIcon]="true"
        inputId="fromDate"
        formControlName="fromDate"
        [maxDate]="dateFilterForm.value.toDate"
        dateFormat="dd/mm/yy"
        (onSelect)="onDateSelect('fromDate')"
      />
      <label for="fromDate">From</label>
    </span>

    <span class="p-float-label mt-4">
      <p-calendar
        [showIcon]="true"
        inputId="toDate"
        dateFormat="dd/mm/yy"
        formControlName="toDate"
        [minDate]="dateFilterForm.value.fromDate"
        (onSelect)="onDateSelect('toDate')"
      />
      <label for="toDate">To</label>
    </span>

    <p-divider></p-divider>

    <div class="flex gap-4 justify-content-between">
      <span>
        <p-radioButton
          formControlName="timeOption"
          name="timeOption"
          value="All Day"
          inputId="allDay"
        />
        <label
          for="allDay"
          class="ml-2"
          >All Day</label
        >
      </span>

      <span>
        <p-radioButton
          formControlName="timeOption"
          name="timeOption"
          value="Specific Time"
          inputId="specificTime"
        />
        <label
          for="specificTime"
          class="ml-2"
          >Specific Time</label
        >
      </span>
    </div>

    @if (dateFilterForm.value.timeOption === 'Specific Time') {
      <div class="flex gap-4 mt-4">
        <span class="p-float-label">
          <p-calendar
            [showTime]="true"
            [timeOnly]="true"
            inputId="fromTime"
            [hourFormat]="'12'"
            formControlName="fromTime"
            (onSelect)="onChangeTime($event, 'from')"
          />
          <label for="fromTime">From</label>
        </span>

        <p-selectButton
          [options]="stateOptions"
          formControlName="fromAmPm"
          optionLabel="label"
          optionValue="value"
          (onChange)="onAmPmChange($event.value, 'from')"
        ></p-selectButton>
      </div>

      <div class="flex gap-4 mt-4">
        <span class="p-float-label">
          <p-calendar
            [showTime]="true"
            [timeOnly]="true"
            inputId="toTime"
            [hourFormat]="'12'"
            formControlName="toTime"
            (onSelect)="onChangeTime($event, 'to')"
          />
          <label for="toTime">To</label>
        </span>

        <p-selectButton
          [options]="stateOptions"
          formControlName="toAmPm"
          optionLabel="label"
          optionValue="value"
          (onChange)="onAmPmChange($event.value, 'to')"
        ></p-selectButton>
      </div>
    }
  </form>

  <p-divider></p-divider>

  <div class="flex gap-4 justify-content-between">
    <p-button
      pRipple
      [outlined]="true"
      (click)="clearFilter()"
    >
      {{ 'Clear' }}
    </p-button>
    <p-button
      pRipple
      (click)="applyFilter()"
      [disabled]="!dateFilterForm.valid"
    >
      {{ 'Apply' }}
    </p-button>
  </div>
</div>
