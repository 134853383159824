// @ts-nocheck
var executeSnippetV1 = function executeSnippetV1() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$wnd = _ref.wnd,
    wnd = _ref$wnd === void 0 ? window : _ref$wnd;
  (function (m, n, e, t, l, o, g, y) {
    if (e in m) {
      if (m.console && m.console.log) {
        m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
      }
      return;
    }
    g = m[e] = function (a, b, s) {
      g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
    };
    g.q = [];
    o = n.createElement(t);
    o.async = 1;
    o.crossOrigin = 'anonymous';
    o.src = 'https://' + _fs_script;
    y = n.getElementsByTagName(t)[0];
    y.parentNode.insertBefore(o, y);
    g.identify = function (i, v, s) {
      g(l, {
        uid: i
      }, s);
      if (v) g(l, v, s);
    };
    g.setUserVars = function (v, s) {
      g(l, v, s);
    };
    g.event = function (i, v, s) {
      g('event', {
        n: i,
        p: v
      }, s);
    };
    g.anonymize = function () {
      g.identify(!!0);
    };
    g.shutdown = function () {
      g("rec", !1);
    };
    g.restart = function () {
      g("rec", !0);
    };
    g.log = function (a, b) {
      g("log", [a, b]);
    };
    g.consent = function (a) {
      g("consent", !arguments.length || a);
    };
    g.identifyAccount = function (i, v) {
      o = 'account';
      v = v || {};
      v.acctId = i;
      g(o, v);
    };
    g.clearUserCookie = function () {};
    g.setVars = function (n, p) {
      g('setVars', [n, p]);
    };
    g._w = {};
    y = 'XMLHttpRequest';
    g._w[y] = m[y];
    y = 'fetch';
    g._w[y] = m[y];
    if (m[y]) m[y] = function () {
      return g._w[y].apply(this, arguments);
    };
    g._v = "1.3.0";
  })(wnd, wnd.document, wnd['_fs_namespace'], 'script', 'user');
};
var getDocumentVar = function getDocumentVar(globalVar) {
  return globalVar === 'window' ? 'document' : "".concat(globalVar, ".document");
};
var toV1String = function toV1String() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref2$shouldInsertScr = _ref2.shouldInsertScript,
    shouldInsertScript = _ref2$shouldInsertScr === void 0 ? true : _ref2$shouldInsertScr,
    _ref2$globalVar = _ref2.globalVar,
    globalVar = _ref2$globalVar === void 0 ? 'window' : _ref2$globalVar,
    _ref2$apiVersion = _ref2.apiVersion,
    apiVersion = _ref2$apiVersion === void 0 ? '1.3.0' : _ref2$apiVersion;
  return "(function(m,n,e,t,l,o,g,y){\n    if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window[\"_fs_namespace\"].');} return;}\n    g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];".concat(shouldInsertScript ? "\n    o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;\n    y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);" : '', "\n    g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};\n    g.anonymize=function(){g.identify(!!0)};\n    g.shutdown=function(){g(\"rec\",!1)};g.restart=function(){g(\"rec\",!0)};\n    g.log = function(a,b){g(\"log\",[a,b])};\n    g.consent=function(a){g(\"consent\",!arguments.length||a)};\n    g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};\n    g.clearUserCookie=function(){};\n    g.setVars=function(n, p){g('setVars',[n,p]);};\n    g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];\n    if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};\n    g._v=\"").concat(apiVersion, "\";\n})(").concat(globalVar, ",").concat(getDocumentVar(globalVar), ",").concat(globalVar, "['_fs_namespace'],'script','user');");
};
var initFS = function initFS(_a) {
  var orgId = _a.orgId,
    _b = _a.namespace,
    namespace = _b === void 0 ? 'FS' : _b,
    _c = _a.debug,
    debug = _c === void 0 ? false : _c,
    _d = _a.host,
    host = _d === void 0 ? 'fullstory.com' : _d,
    _e = _a.script,
    script = _e === void 0 ? 'edge.fullstory.com/s/fs.js' : _e;
  if (!orgId) {
    throw new Error('FullStory orgId is a required parameter');
  }
  window['_fs_debug'] = debug;
  window['_fs_host'] = host;
  window['_fs_script'] = script;
  window['_fs_org'] = orgId;
  window['_fs_namespace'] = namespace;
  executeSnippetV1();
};
var SnippetSrc = toV1String();
var generateSnippetText = function generateSnippetText(_a) {
  var orgId = _a.orgId,
    _b = _a.namespace,
    namespace = _b === void 0 ? 'FS' : _b,
    _c = _a.debug,
    debug = _c === void 0 ? false : _c,
    _d = _a.host,
    host = _d === void 0 ? 'fullstory.com' : _d,
    _e = _a.script,
    script = _e === void 0 ? 'edge.fullstory.com/s/fs.js' : _e;
  if (!orgId) {
    throw new Error('FullStory orgId is a required parameter');
  }
  return "window['_fs_debug'] = ".concat(debug, ";\nwindow['_fs_host'] = '").concat(host, "';\nwindow['_fs_script'] = '").concat(script, "';\nwindow['_fs_org'] = '").concat(orgId, "';\nwindow['_fs_namespace'] = '").concat(namespace, "';\n").concat(SnippetSrc, "\n");
};
export { SnippetSrc, generateSnippetText, initFS };